import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import React from "react"
import SiteMetadata from "../components/SiteMetadata"
import Layout from "../layouts/Layout"

export default (props) => {
  const {
    gallery,
    name,
    summary,
  } = props.data.item

  return (
    <Layout>
      <SiteMetadata
        title={name}
        description={summary}
      />
      <div className="bg-gray-100 py-12 lg:py-16">
        <div style={{ maxWidth: 800 }} className="container">
          <div className="flex flex-col">
            {gallery && gallery.map(item => (
              <div key={item.id} className="bg-white p-4 mb-4">
                <Img fluid={item.localFile.childImageSharp.fluid} alt={item.description} />
              </div>
            ))}
          </div>
        </div>

        <div className="text-center mx-auto mt-20">
          <Link to="/contact" className="bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-4 px-6 mx-4 border border-blue-500 hover:border-transparent rounded-full">Request a Session</Link>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query PortfolioItemQuery($slug: String!) {
    item: contentfulPortfolio(slug: { eq: $slug }) {
      description {
        description
      }
      name
      summary
      gallery {
        id
        description
        title
        localFile {
          childImageSharp {
            fluid(maxWidth: 800, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`
